<template>
  <div class="f-b-button" :style="styles">
    <div class="_point" @click="myclick">
      <span class="text textb">{{ $t('orderInfo.see_history_records') }}</span>
      <span class="_rel" style="margin-left: 8px">
        <img
          v-if="!showFeedbackRecords"
          class="_ab-v-center"
          :src="require('@/assets/image/feedbackRecords-arrow-bottom.png')"
          alt=""
        />
        <img v-else class="_ab-v-center" :src="require('@/assets/image/feedbackRecords-arrow-top.png')" alt="" />
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FRButton',
  data() {
    return {
      showFeedbackRecords: false,
    }
  },
  props: {
    styles: {
      type: String,
      default: '',
    },
  },
  methods: {
    myclick() {
      this.$emit('my-click')
      this.showFeedbackRecords = !this.showFeedbackRecords
    },
  },
}
</script>

<style lang="less" scoped>
.text {
  flex: 1;
  width: 0;
  color: @text_color_2;
  font-size: @font_size_2;
  .download-wrap {
    /deep/.ant-space {
      flex-wrap: wrap;
      .ant-btn-link {
        height: 17px;
        line-height: 17px;
        margin-bottom: 5px;
      }
    }
  }
}
.textb {
  font-weight: 400;
  line-height: 16px;
  color: #4080ff;
}
</style>
